import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';
import { convertirAMoneda } from '../../../utils/currency';

export const BankBestOption = ({
  imageUrl,
  cat,
  monthlyPaid,
  tasa,
  product,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.imageContent}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={imageUrl} alt="Bank Logo" />
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.dataContainer}>
          <div className={classes.column}>
            <div className={classes.propContainer}>
              <Typography className={classes.propTitle}>
                CAT:
              </Typography>
              <Typography className={classes.propValue}>
                {`${cat}%`}
              </Typography>
            </div>
            <div className={classes.propContainer}>
              <Typography className={classes.propTitle}>
                Tasa:
              </Typography>
              <Typography className={classes.propValue}>
                {`${tasa}%`}
              </Typography>
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.propContainer}>
              <Typography className={classes.propTitle}>
                Mensualidad:
              </Typography>
              <Typography className={classes.propValue}>
                {convertirAMoneda(monthlyPaid)}
              </Typography>
            </div>
            <div className={classes.propContainer}>
              <Typography className={classes.propTitle}>
                Producto:
              </Typography>
              <Typography className={classes.propValue}>
                {product}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

BankBestOption.propTypes = {
  imageUrl: PropTypes.string,
  cat: PropTypes.number,
  monthlyPaid: PropTypes.string,
  tasa: PropTypes.string,
  product: PropTypes.string,
};

BankBestOption.defaultProps = {
  imageUrl: '',
  cat: 0,
  monthlyPaid: '',
  tasa: '',
  product: '',
};
