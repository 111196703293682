/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Input from '../Input';
import useStyles from './useStyles';
import { HelpButton } from '../HelpButton';

const DEFAULT_ITEM_SIZE = 12;

export const GroupFields = ({
  fields, title, position, onChange, handleHeadersAndValues, customTitleClass,
  disabledSM, customGridClass, makeFieldsRequired, extraInputClassName, inputClassName,
}) => {
  const classes = useStyles();
  return (
    <>
      {title ? <Typography component="h3" className={clsx(classes.title, customTitleClass)}>{title}</Typography> : ''}
      <Grid className={classes.gridContainer} container spacing={2}>
        {
          fields.map((field) => (
            <Grid
              key={field._id}
              item
              md={field?.config?.itemSize || DEFAULT_ITEM_SIZE}
              sm={disabledSM ? 12 : (fields.length === 1 ? 12 : 12)}
              xs={12}
              className={clsx(
                classes.gridItem,
                {
                  [customGridClass]: customGridClass,
                },
              )}
            >
              <Input
                label={field.label}
                type={field?.fieldType?.name}
                name={field.name}
                value={field.value || ''}
                options={field.config.options ?? []}
                headers={field?.config?.data || []}
                required={Boolean(field?.config?.required)}
                onChange={(event, value) => onChange(event, value, position, field.name)}
                handleHeadersAndValues={
                  (inputValue) => handleHeadersAndValues(field, inputValue, position)
                }
                disabled={field?.config?.disabled}
                hide={Boolean(field?.config?.hide)}
                isEmpty={field?.isEmpty}
                errorMessage={field?.errorMessage}
                defaultValue={field?.config?.defaultValue}
                rejections={field?.status?.payload?.rejections
                  && field?.status?.payload?.rejections[0]}
                status={field?.status?.value}
                format={field?.config?.format}
                className={clsx(classes.input, {
                  [classes.helpButton]: !!field?.config?.helpButton,
                  [inputClassName]: inputClassName,
                })}
                multiUpload={field?.config?.multiUpload || false}
                makeRequired={makeFieldsRequired ? Boolean(field?.config?.required) : false}
                extraInputClassName={extraInputClassName}
                minDateRange={field?.config?.minDateRange}
                maxDateRange={field?.config?.maxDateRange}
              />
              {
                field?.config?.helpButton && (
                  <HelpButton key={field._id} helpData={field?.config?.helpData} />
                )
              }
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

GroupFields.propTypes = {
  fields: PropTypes.array.isRequired,
  title: PropTypes.string,
  position: PropTypes.number,
  onChange: PropTypes.func,
  handleHeadersAndValues: PropTypes.func,
  customTitleClass: PropTypes.string,
  disabledSM: PropTypes.bool,
  customGridClass: PropTypes.string,
  makeFieldsRequired: PropTypes.bool,
  extraInputClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

GroupFields.defaultProps = {
  title: '',
  position: 0,
  onChange: () => { },
  handleHeadersAndValues: () => { },
  customTitleClass: '',
  disabledSM: false,
  customGridClass: '',
  makeFieldsRequired: false,
  extraInputClassName: '',
  inputClassName: '',
};
