import { useContext } from 'react';
import { ErrorContext } from '../context/error/errorContext';
import { createErrorLog } from '../api/logs';
import { useSessionRewind } from './useSessionRewind';
import { objectUtils } from '../utils';
import { IS_PRODUCTION } from '../config/envs';

const ORIGIN_CHIPLO = ' PORTAL CHIPLO';

export const useLogger = () => {
  const { setError } = useContext(ErrorContext);
  const { getSessionUrlCurrentTime, logSessionError } = useSessionRewind();

  const logErrorMessage = (origin, error) => {
    if (IS_PRODUCTION) return;
    // console.error('🚀 ~ Error at:', origin, error);
    console.error(`🚀🚀🚀 ~ Error at: ${origin} 🚀🚀🚀`, error);
  };

  const getDataError = (error) => {
    if (error.response) {
      return error.response.data || error.response || error;
    }
    if (error.message) {
      return error.message;
    }
    return error;
  };

  const getExtraDataError = (error, extraData = '') => {
    // eslint-disable-next-line prefer-const
    let errorExtraData = extraData ? { ...extraData } : {};

    if (objectUtils.isValidObject(error.config)) {
      errorExtraData.url = error.config.url;
      errorExtraData.method = error.config.method;
      errorExtraData.headers = error.config.headers;
      errorExtraData.body = error.config.data;
    }
    return errorExtraData;
  };

  const logError = async ({
    error,
    level,
    message,
    origin,
    data = '',
    extraData = '',
    errorUrl = '',
  }) => {
    logErrorMessage(origin, error);
    const url = errorUrl || getSessionUrlCurrentTime() || '';
    const dataError = getDataError(error) || '';
    const extraDataError = getExtraDataError(error, extraData);
    await createErrorLog({
      level,
      message,
      origin: `${ORIGIN_CHIPLO} - ${origin}`,
      data: dataError || data || '',
      extraData: extraDataError,
      errorUrl: url,
    });
    setError({ message });
    const extraDataToLog = objectUtils.convertObjectToString(dataError) || message;
    logSessionError(error, extraDataToLog);
  };

  return {
    logError,
  };
};
