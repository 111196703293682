/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './useStyles';
import { logout } from '../../api/auth';
import { isAuth, getFullName, isUserAgent } from '../../utils/auth';
import { handleLogoutRediection } from '../../utils/logout';
import { ThemeContext } from '../../context/Theme/themeContext';
import { AGENTE_LOGIN_ROUTE, APP_MAIN_ROUTE } from '../../constants';

const LOGO = 'https://fintecimal-common.s3.amazonaws.com/FlowsEmailTemplate/1chiplo.png';

export const MenuAppBar = ({ children, navigateOnLogoClick, displayAuthUser = true }) => {
  const { state: { logo } } = useContext(ThemeContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const showUserMenu = () => {
    if (!displayAuthUser) return false;
    return isAuth() && isUserAgent();
  };

  const handleMenu = (event) => {
    if (showUserMenu()) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOut = async () => {
    const isAUserAgent = isUserAgent();
    const response = await logout();
    if (response) {
      setAnchorEl(null);
      const routeRedirection = isAUserAgent ? AGENTE_LOGIN_ROUTE : '';
      await handleLogoutRediection(routeRedirection);
    }
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            <div
              className={classes.logo}
              style={{ backgroundImage: `url(${logo || LOGO})` }}
              onClick={
                navigateOnLogoClick ? () => navigate(APP_MAIN_ROUTE) : null
              }
            />
          </Typography>
          <div>
            {displayAuthUser && isAuth() && (
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="default"
                className={classes.button}
              >
                <span className={classes.userName}>{getFullName()}</span>
                {
                  showUserMenu() && (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )
                }
              </Button>
            )}
            {showUserMenu() && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={onLogOut}>Salir</MenuItem>
              </Menu>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

MenuAppBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  navigateOnLogoClick: PropTypes.bool,
  displayAuthUser: PropTypes.bool,
};

MenuAppBar.defaultProps = {
  navigateOnLogoClick: false,
  displayAuthUser: true,
  children: null,
};
