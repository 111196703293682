import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@material-ui/core';

import useStyles from './useStyles';
import { BankBestOption } from '../BankBestOption';

export const BankBestOptionContainer = ({
  title,
  imageUrl,
  cat,
  mensualidad,
  tasa,
  producto,
}) => {
  const classes = useStyles();
  return (
    <Container
      disableGutters
      maxWidth={false}
      className={classes.container}
    >
      <Box className={classes.bestOptionContainer}>
        <Typography className={classes.titleBestOption}>{title}</Typography>
        <BankBestOption
          imageUrl={imageUrl}
          cat={cat}
          monthlyPaid={mensualidad}
          tasa={tasa}
          product={producto}
        />
      </Box>
    </Container>
  );
};

BankBestOptionContainer.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  cat: PropTypes.number,
  mensualidad: PropTypes.string,
  tasa: PropTypes.string,
  producto: PropTypes.string,
};

BankBestOptionContainer.defaultProps = {
  title: '',
  imageUrl: '',
  cat: 0,
  mensualidad: '',
  tasa: '',
  producto: '',
};
